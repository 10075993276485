#personal-details-section {
  background-color: #EFEFEF;
  text-align: center;
  z-index: 6;
}

#personal-details-section h1 {
  color: #2d3e50;
}

@media (min-width: 450px) {
  #personal-details-section .cv-details .cv-detail {
    min-width: 400px;
  }
}