.home-page {
  flex-grow: 1;
  display: flex;
  width: 75%;
  margin: 0 auto;
  align-items: center !important;
  justify-content: center;
  text-align: center;
  font-size: 150%;
}

.introduction {
  font-size: 4rem;
}