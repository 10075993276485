.app-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: column;
  align-items: center;
}

.app-metadata {
  margin-right: 1rem;
}

.app-metadata .app-header {
  display: flex;
  flex-flow: wrap;
  align-items: baseline;
  justify-content: space-between;
}

.app-summary .app-name {
  margin-right: 0.5rem;
  font-weight: bolder;
}

.app-summary .app-name,
.app-summary .app-price {
  margin-top: 0;
  margin-bottom: 0.25rem;
}

.app-image {
  max-width: 55%;
  margin-bottom: 1rem;
}

@media (min-width: 650px) {
  .app-container {
    flex-flow: row;
  }

  .app-image {
    flex: 0 0 40%;
    order: 1;
    margin-bottom: 0;
  }

  .app-metadata {
    order: 0;
  }
}

.app-image img {
  width: 100%;
}