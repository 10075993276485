.cv-section-container {
  width: 100%;
}

.cv-section-container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
  min-height: 40vh;
  padding: 1em 0;
}

.cv-section-hidden {
  display: none;
}

@media (min-width: 900px) {
  .cv-section-container > div {
    width: 80%;
  }
}

.cv-section-title {
  font-size: 2em;
  text-align: center;
}