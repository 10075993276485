* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

#root {
  min-height: 100%;
}

#root {
  display: flex;
}

#root > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#root main {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  justify-content: center;
  flex-flow: column;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'sans-serif';
  color: #333;
  background-color: #fdfdfd;
}

.sticky-sidebar {
  list-style: none;
}

@media (min-width: 725px) {
  #root main {
    flex-flow: row;
  }

  .sticky-sidebar {
    min-height: 90vh;
  }

  @supports (position: sticky) {
    .csspositionsticky .sticky-sidebar {
      position: sticky;
      top: 0;
    }
  }
}

a[href] {
  color: #975d89;
  text-decoration: none;
}

a[href]:hover {
  border-bottom: 1px dotted #dc8ec9;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 0.1em 10px 1em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  font-size: 90%;
}

blockquote p {
  margin: 0;
}

blockquote p:not(:last-child) {
  padding-bottom: 1rem;
}

*::selection {
  background-color: #dc8ec9;
}

ul {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

ul > li {
  margin-bottom: 0.5rem;
}