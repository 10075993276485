.select-list {
  display: flex;
  flex-flow: wrap;
  align-content: center;
  justify-content: center;
  margin: 1rem;
  margin-bottom: 0;
  transition: all 1s;
}

@media (min-width: 725px) {
  .select-list {
    margin-bottom: 1rem;
    flex: 0 0 75%;
  }
}

.select-list:not(.has-selection) li {
  margin: 5%;
  flex: 0 0 25%;
}

.select-list.has-selection li {
  flex: 0 0 100%;
}

.select-list.has-selection li a:after {
  left: 100%;
}

.select-list li.selected a:after,
.select-list:not(.has-selection) li:hover a:after {
  width: 100%;
  left: 0;
}

.select-list.has-selection li img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
