.selected-project {
  margin: 1rem;
  margin-right: 2rem;
  font-size: 120%;
  flex: 2;
}

.selected-project-section {
  margin-bottom: 1rem;
}

.selected-project-metadata {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.selected-project .selected-project-header {
  width: 100%;
}

.selected-project .selected-project-links {
  float: right;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 410px) {
  .selected-project .selected-project-links {
    width: 100%;
    flex-flow: wrap;
  }
}

.selected-project .selected-project-links a {
  margin: 0.5rem;
}

.selected-project .selected-project-links a:hover {
  border: none;
}

.selected-project-screenshots {
  display: flex;
  width: 100%;
  flex-flow: wrap;
}

.selected-project-screenshots > div {
  flex: 1;
  margin-right: 1rem;
}

@media (max-width: 550px) {
  .selected-project-screenshots {
    justify-content: center;
  }

  .selected-project-screenshots > div {
    min-width: 55%;
    max-width: 55%;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }
}

@media (max-width: 450px) {
  .selected-project-screenshots > div {
    min-width: 75%;
    max-width: 75%;
  }
}

.selected-project-screenshots > div:last-child {
  margin-right: 0;
}

.selected-project-screenshots > div > img {
  max-width: 100%;
  max-height: 100%;
}

.selected-project h3,
.selected-project ul {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.selected-project li {
  padding-bottom: 0.75rem;
  padding-left: 0;
}

.selected-project-installation-instructions li {
  list-style: decimal;
}