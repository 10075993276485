.navigation-bar {
  display: flex;
  width: 100%;
  background-color: black;
  justify-content: space-between;
  flex-flow: wrap;
}

@media (max-width: 500px) {
  .navigation-bar {
    justify-content: center;
  }
}

.navigation-bar > div {
  display: flex;
  flex-flow: wrap;
}

.navigation-bar a {
  border-bottom: 0;
  color: white;
  padding: 0.75rem 1rem;
  display: block;
  font-size: 115%;
  font-weight: bold;
}

.navigation-bar a:hover {
  border-bottom: 0;
  color: #CCC;
}