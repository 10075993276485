.cv-details {
  display: flex;
  flex-flow: wrap;
}

.cv-details .cv-detail {
  margin: 1rem;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
}

@media (min-width: 300px) {
  .cv-details .cv-detail {
    min-width: 200px;
  }
}